<template>
  <div class="flex flex1 height_100p">
    <Sidebar showTag :tagList="tagList" @searchList="searchList" @changeTags="changeTags"></Sidebar>
    <div class="course-container flex1">
      <div class="list-toolbar flex flex_jcbetween flex_end">
        <div class="list-select flex3 fontsize_14 color_26 flexshrink" >
          <!-- <el-select
            class="noborder"
            v-model="sort_mode"
            placeholder=""
            @change="changeOrder"
            popper-class="list-order-tool"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in orderList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <fdDropdown 
            v-model="sort_mode"
            :list="orderList"
            @change="changeOrder"></fdDropdown>
        </div>
        <!-- <div class="flex2 fontsize_12 color_222 flexshrink" v-if="currentStyle == 1">
          {{ $t("lang.updatetime") }}
        </div> -->
        <div style="width: 120px" class="style-list flex flexshrink flex_end">
          <div
            v-for="(item, sind) in styleList"
            :key="item.value"
            @click="changeStyle(sind)"
            :class="{ margin_left8: sind !== 0 }"
            class="pointer"
          >
            <i
              :class="[
                item['icon' + (currentStyle == sind ? 1 : 0)],
                'fontsize_24',
              ]"
            >
              <span
                :class="'path' + (pind + 1)"
                v-for="(pitem, pind) in item.iconpath"
                :key="pind"
              ></span>
            </i>
          </div>
        </div>
      </div>
      <div v-show="currentStyle == 1" class="horizontal-header flex flex_acenter fontsize_14 color_59">
        <span>封面</span>
        <span>名称</span>
        <span>最后编辑时间</span>
        <span>标签</span>
        <span>操作</span>
      </div>
      <!-- flex flexwrap flex_contentstart  -->
      <!-- v-infinite-scroll="loadData" infinite-scroll-distance="200" -->
      <el-scrollbar
        :wrapStyle="[{'overflow-x':'hidden'}]"
        class="list-container"
        :class="{ 'margin_top16': currentStyle == 0, 'grid_type': currentStyle == 0 && courseListLen, 'list_nodata': total == 0 }"
        ref="infiniteScroll"
        :style="{'--right': currentStyle == 0 ? '-38px': 0}"
        >
        <div
          class="coursebox-wrapper bgc_fff pointer"
          v-for="(item, ind) in courseList"
          :key="item.id+ '_0'"
          :data-id="item.id"
          v-show="currentStyle == 0"
          @click.stop="handleTools({ type: 'edit', item: item, index: ind })"
          >
          <div class="coursebox-image prel" :style="{'background-image': `url(${item.cover})`}">
            <!-- <el-image
              :src="item.cover"
              class="coursebox-image"
              fit="cover"
            ></el-image> -->
            <div
              class="coursebox-mask"
              :class="{ show: isshow && showIndex == ind }"
            >
              <el-dropdown
                :ref="'dropdown_0_'+ item.id"
                @click.stop=""
                @command="handleTools"
                @visible-change="toolsVisibleChange"
                class="coursebox-mask-edit"
                :show-timeout="500"
                :hide-timeout="200"
                placement="bottom"
              >
                <span @click.stop="" class="el-dropdown-link coursebox-mask-edit pointer">
                  <i class="icon-gray-more fontsize_18"></i>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  @mouseenter.native="toolshover(1, ind)"
                  @mouseleave.native="toolshover(2, ind)"
                  :append-to-body="false"
                >
                  <el-dropdown-item :command="commandVal('edit', item, ind)">{{
                    $t("lang.edit")
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('changecover', item, ind)">{{
                    $t("lang.changecover")
                  }}</el-dropdown-item>
                  <el-dropdown-item
                    :command="commandVal('previewcourseware', item, ind)"
                    divided
                    >{{ $t("lang.previewcourseware") }}</el-dropdown-item
                  >
                  <el-dropdown-item :command="commandVal('addlabel', item, ind)">{{
                    $t("lang.addlabel")
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('copying', item, ind)">{{
                    $t("lang.copying")
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('rename', item, ind)">{{
                    $t("lang.rename")
                  }}</el-dropdown-item>
                  <el-dropdown-item
                    :command="commandVal('delete', item, ind)"
                    divided
                    ><span class="color_E74362">{{ $t("lang.delete") }}</span></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div
                class="coursebox-mask-tools pointer"
              >
                <i class="icon-whitebg-edit"></i>
              </div>
            </div>
          </div>
          <div
            class="
              coursebox-title
              fontsize_14
              color_222
              padding_lr16 pover
            "
            v-html="item.title" :title="item.title"></div>
          <div
            class="
              coursebox-time
              fontsize_12
              color_999
              padding_bot16
              margin_top3
              padding_lr16
            ">
            {{item.created_at}}
          </div>
        </div>
        <!-- 横排 -->
        <div v-show="currentStyle == 1" class="coursebox-horizontal-wrapper">
          <div
            class="coursebox-horizontal flex flex_acenter bgc_fff pointer "
            :class="{ show: isshow && showIndex == ind }"
            v-for="(item, ind) in courseList"
            :key="item.id + '_1'"
            :data-id="item.id"
            @click="handleTools({ type: 'edit', item: item, index: ind })"
            >
            <div class="flexshrink">
              <el-image
                :src="item.cover"
                fit="cover"
                class="coursebox-horizontal-image"
              ></el-image>
            </div>
            <div class="coursebox-horizontal-title fontsize_14 color_26 flexshrink">
              <p class="pover2" :title="item.title">{{item.title}}</p>
            </div>
            <div class="coursebox-horizontal-time flexshrink">
              <span class="fontsize_14 color_26">{{item.updated_date}}</span>
            </div>
            <div  class="coursebox-horizontal-tags flexshrink" style="max-width:20%;">
              <span v-for="(tagone, tagindex) in item.tag_list" 
                v-show="tagindex <= 3 && item.tag_list.length <= 4 || tagindex <= 2 && item.tag_list.length > 4" 
                :key="tagone.id" class="fontsize_14 color_26 pover" style="max-width:80%;">
                  {{tagone.tag_name}}
                </span>
              <span v-if="item.tag_list && item.tag_list.length > 4" @click.stop="moreTags(item.tag_list, ind)" class="tags_dot" @mouseleave="showTags = false">…</span>
              <div class="more_tags" :class="{'show': showTags && ind == tagsIndex}" @mouseleave="showTags = false">
                <p v-for="(tagone, tagindex) in more_tag_list" :key="tagone.id" class="fontsize_14 color_26 pover">
                  {{tagone.tag_name}}
                </p>
              </div>
            </div>
            <div class="coursebox-horizontal-tools flexshrink">
              <el-dropdown
                :ref="'dropdown_1_'+ item.id"
                class="tools-dropdown"
                @command="handleTools"
                @visible-change="toolsVisibleChange"
              >
                <span class="el-dropdown-link pointer" style="padding: 10px 15px">
                  <i class="icon-gray-more fontsize_24 color_A8A8A8"></i>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  @mouseenter.native="toolshover(1, ind)"
                  @mouseleave.native="toolshover(2, ind)"
                >
                  <el-dropdown-item :command="commandVal('edit', item, ind)">{{ $t("lang.edit") }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('changecover', item, ind)">{{ $t("lang.changecover") }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('previewcourseware', item, ind)" divided >{{ $t("lang.previewcourseware") }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('addlabel', item, ind)">{{ $t("lang.addlabel") }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('copying', item, ind)">{{ $t("lang.copying") }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('rename', item, ind)">{{ $t("lang.rename") }}</el-dropdown-item>
                  <el-dropdown-item :command="commandVal('delete', item, ind)" divided>
                    <span class="color_E74362">{{ $t("lang.delete") }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="nodata" v-if="total == 0">
          <img v-if="keyword != ''" src="@/assets/nodata/course_search_nodata.svg" alt="" class="statu_img">
          <img v-else src="@/assets/nodata/couse_nodata.svg" alt="" class="statu_img">
          <span class="margin_top16 fontsize_12 color_A1A1A1">{{ keyword != '' ? '无搜索内容' : '暂无课程'}}</span>
        </div>
      </el-scrollbar>
      <fd-dialog
        :title="dialogDetail.title"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="handleClose"
        custom-class="course-dialog not-full">
        <p class="fontsize_14 color_222 margin_bot8">{{dialogDetail.label}}</p>
        <el-form @submit.native.prevent>
          <el-form-item >
            <el-input v-if="dialogDetail.type !='addlabel'" v-model="inputVal" autocomplete="off" :placeholder="dialogDetail.placeholder" class="h48 border_D1 noborder"></el-input>
            <el-select v-else
              v-model="dialogDetail.oldTag"
              value-key="tag_name"
              :remote-method="remoteTags"
              @change="changeSelectTag($event,'')"
              multiple
              filterable
              remote
              allow-create
              default-first-option :placeholder="dialogDetail.placeholder"
              class="select-allow-create noborder width_100p" style="width:100%">
              <el-option
                v-for="item in tagList"
                :key="item.id"
                :label="item.tag_name"
                :value="item.tag_name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <button type="primary" @click="save" class="course-save pointer">保存</button>
          <button @click="dialogVisible = false" class="course-close pointer">关闭</button>
        </span>
      </fd-dialog>
      <fd-dialog
        :title="dialogDetail.title"
        :visible.sync="delVisible"
        width="600px"
        :before-close="handleClose"
        custom-class="course-dialog not-full">
        <p class="fontsize_16 color_222 textcenter margin_bot16" style="padding-top:9px;">请确认是否删除课程</p>
        <p class="fontsize_14 color_E74362 textcenter" style="padding-bottom:9px;">您确认要删除课程吗？删除之后将无法找回课程</p>
        <span slot="footer" class="dialog-footer">
          <button type="primary" @click="delCourse" class="course-save pointer">确定删除</button>
          <button @click="delVisible = false" class="course-close pointer">不删了</button>
        </span>
      </fd-dialog>
      <mediaSelectDialog 
        :visible="coverDialogSet.visible" 
        :title="coverDialogSet.title" 
        :fromDetail="coverDialogSet.item" 
        :mediaType="coverDialogSet.mediaType"
        @select="selectCover" 
        @close="closeMediaDialog"
      ></mediaSelectDialog>
    </div>
    <fd-dialog
      fullscreen
      lock-scroll
      :show-close="false"
      :visible.sync="previewLoading.visible"
      custom-class="preview-loading-dialog">
      <div class="preview-loading-container">
        <img src="@/assets/fd-loading.png" class="loading-img" />
        <div class="flex flex_acenter fontsize_14 color_222">
          <p>加载中</p>
        </div>
        <div @click="cancelPreview" class="cancel-btn flex flex_acenter flex_center"><span class="fontsize_14 color_222">取消</span></div>
      </div>
    </fd-dialog>
    <fd-dialog
      :title="previewDialogSet.title"
      :visible.sync="previewDialogSet.visible"
      width="600px"
      :before-close="handleClose"
      :custom-class="{'course-dialog not-full preview-error-dialog':true}">
      <div class="course-preview-error">
        <p class="margin_bot16 fontsize_16 color_222 textcenter">{{previewDialogSet.p1}}</p>
        <p class="margin_bot16 fontsize_12 color_E74362 textcenter">{{previewDialogSet.p2}}</p>
        <el-scrollbar class="error-scrollview" :wrapStyle="[{'overflow-x':'hidden'}]">
          <div style="height:300px;" class="padding_0_16">
            <errorNavItem v-for="(item,ind) in previewDialogSet.items" :key="ind" :detail="item" @click="navtoError" class="margin_top16"></errorNavItem>
          </div>
        </el-scrollbar>
        <div class="dialog-footer flex flex_center margin_top16">
          <button type="primary" @click="previewDialogSet.visible = false" class="course-save pointer">确认</button>
          <button @click="previewDialogSet.visible = false" class="course-close pointer">关闭</button>
        </div>
      </div>
    </fd-dialog>
    <fd-upgrade-dialog :closeOnClickModal="false" :show.sync="upgradeDialogVisible" @close="handleUpgradeClose"></fd-upgrade-dialog>
    <hint-dialog :visible.sync="tipVisible" :hint="hintObj" @click="toUpgrade" :ismore="true"></hint-dialog>
  </div>
</template>

<script>
import Config from '@/libs/config';
import { debounce } from '@/libs/utils/tools';
import { mapActions, mapMutations, mapState } from 'vuex';
import { updateCourseCover, addCourseTag, updateCourseTag } from '@/libs/api/course';
import { previewCourse } from '@/libs/api/others';
import { Sidebar } from '@/renderer/components/Dashboard/components';
import mediaSelectDialog from '@/renderer/components/common/mediaSelectDialog';
import errorNavItem from '@/renderer/components/common/errorNavItem.vue';
import fdDropdown from '@/renderer/components/FormItem/fd-dropdown.vue';

export default {
  components:{
    Sidebar,
    mediaSelectDialog,
    errorNavItem,
    fdDropdown
  },
  data() {
    return {
      courseList: [],
      page:1,
      total: 0,
      limit:20,
      keyword: "",
      tagKey: "",
      tagList:[], //标签列表
      sort_mode: "default", //课程排序模式
      orderList: [
        { label: "最近的", value: "default" },
        { label: "按字母a到z顺序", value: "code_asc" },
        { label: "按字母z到a顺序", value: "code_desc" },
      ],
      // currentStyle: 0,
      styleList: [
        {
          icon0: "icon-grid-layout-custom",
          icon1: "icon-grid-layout-selected",
          value: "网格",
          iconpath: 5,
        },
        {
          icon0: "icon-horizontally-layout-custom",
          icon1: "icon-horizontally-layout-selected",
          value: "列表",
          iconpath: 4,
        },
      ],
      isshow: false,
      showIndex: -1,
      dialogDetail:{
        title:"",
        placeholder: "",
        label:"",
        item: {},
        oldTag:[],
        index:-1,
      },
      dialogVisible: false,
      delVisible: false,
      inputVal:"",
      coverDialogSet: { //修改封面弹窗
        title:"媒体库",
        visible: false,
        item:{},
        index:-1,
        mediaType:'picture'
      },
      previewDialogSet: { //预览报错弹窗
        title:"预览错误",
        p1:"抱歉，课程存在以下原因无法预览",
        p2:"请修改以下内容后再进行预览",
        visible: false,
        items:{},
        course_id: ""
      },
      previewLoading: {
        visible: false,
        pageFrame:null, //页面
      },
      more_tag_list:[],
      showTags: false,
      tagsIndex:-1,
      infiniteScrollDistance: 100, //触底距离
      hintObj:{},
      tipVisible:false,
      upgradeDialogVisible:false
    };
  },
  watch: {
    $route() {
      this.total = -1;
      this.sort_mode = "default";
      this.keyword = "";
      this.tagKey = "";
      this.courseList = [];
      this.getList();
    }
  },
  computed: {
    ...mapState({
      'currentStyle': state => state.app.currentCourseStyle,
      is_web: state => state.app.isWebSide,
    }),
    courseListLen() {
      return this.courseList.length
    }
  },
  mounted() {
    // 获取基本的scheme信息
    this.GetSchema().then(res => {
      if(res.errorcode == 0) {
        // 测试保存
        // this.$electron && this.$electron.ipcRenderer.send("saveJson",{fileName:'0121', data: JSON.stringify(res.data['global-setting'])})

      }
    })
    // 获取课程列表
    this.getList();
    this.getTagList();
    this.getLanguagePacket();

    this.$refs.infiniteScroll.$refs.wrap.onscroll = this.onscroll;
    this.$electron && window.addEventListener('contextmenu', this.listenerContextmenu);
  },
  beforeDestroy() {
    this.$electron && window.removeEventListener('contextmenu', this.listenerContextmenu);
  },
  methods: {
    ...mapActions([
      'GetSchema',
      'GetUserInfo',
      'GetCourseList',
      'DelCourse',
      'RenameCourse',
      'GetLanguageList',
      'GetCourseTagList',
      'CopyCourse',
      'PreviewCourse'
    ]),
    ...mapMutations(['setPreview','CHANGE_CURRENTCOURSESTYLE','INIT_COURSE_RELATIVE']),
    handleUpgradeClose(done) {
      // done();
    },
    // 去升级
    toUpgrade() {
      this.tipVisible = false;
      this.upgradeDialogVisible = true;
    },
    listenerContextmenu(e) {
      // 课程工具条-显示右键菜单
      e.preventDefault();
      let topParentElement = this.getParent( e.target );
      if(topParentElement) {
        let id = topParentElement.dataset.id;
        let isMore = Array.isArray( this.$refs[`dropdown_${this.currentStyle}_`+id] );

        if(isMore) this.$refs[`dropdown_${this.currentStyle}_`+id][0].show();
        else this.$refs[`dropdown_${this.currentStyle}_`+id].show();
      }
    },
    getParent(element) {
      if(element.classList.contains('coursebox-wrapper') || element.classList.contains('coursebox-horizontal')) return element;
      if( !element.parentElement ) return null;
      return this.getParent( element.parentElement );
    },
    moreTags(list,index) {
      this.more_tag_list = list.slice(3);
      this.showTags = true;
      this.tagsIndex = index;
    },
    onscroll() {
      let { offsetHeight, scrollTop, scrollHeight } = this.$refs.infiniteScroll.$refs.wrap;
      scrollHeight = scrollHeight - this.infiniteScrollDistance;
      if (offsetHeight + scrollTop >= scrollHeight) {
        // reach bottom
        this.loadData();
      }
    },
    loadData() {
      // 加载下一页
      if(this.page * this.limit >= this.total) return;
      this.page++;
      this.getList();
    },
    getTagList(tag_name) {
      // 获取标签列表
      this.GetCourseTagList(tag_name ? tag_name : '').then(res => {
        if(res.errorcode == 0) {
          this.tagList = res.data.data;
          // this.fileTagList = res.data.data;
        }
      }).catch();
    },
    getLanguagePacket() {
      this.GetLanguageList();
    },
    searchList: debounce(
      function({keyword}){
        // 通过关键词搜索
        if(this.keyword === keyword) return;
        this.keyword = keyword;
        this.page=1;
        this.courseList=[];
        this.getList();
      },
      Config.sidebarSearchDebounceDuration, true
    ),
    changeSelectTag(val, prop) {
      // 选中值发生变化
      let {tag_list}= this.dialogDetail.item;
      let newVal = val.filter(v => {
        
        return !tag_list.includes(v)
      });
      
      // this.dialogDetail.oldTag.push(...newVal);
      newVal.forEach(v => {
        addCourseTag(v).then(res => {
          if(res.errorcode == 0) {
            this.dialogDetail.item.tag_list.push(res.data.tag_name);
            this.getTagList();
          }
        })
      } )
    },
    remoteTags(query) {
      // 远程搜索标签
      if(query === '') {
        this.fileTagList = this.tagList;
        return;
      }
      this.GetCourseTagList(query).then(res => {
        if(res.errorcode == 0) {
          this.fileTagList = res.data.data;
          this.$forceUpdate();
        }
      }).catch();
    },
    changeTags(val) {
      // 通过标签搜索
      this.page=1;
      this.courseList=[];
      this.tag_name = val;
      this.getList();
    },
    closeMediaDialog() {
      // 关闭弹窗/后
      this.coverDialogSet.index = -1;
      this.coverDialogSet.visible = false;
      this.coverDialogSet.item = {};
    },
    selectCover({item, fromDetail}) {
      //修改封面
      let index = this.coverDialogSet.index;
      updateCourseCover({course_id: fromDetail.course_id, cover: item.url}).then(res => {
        if(res.errorcode == 0) {
          this.closeMediaDialog();
          // 更新list中的封面
          this.updateItem({course_id:fromDetail.course_id, index, propName:"cover", value:item.url});
        }
      })
    },
    updateItem({course_id, index, propName, value}) {
      // 更新课程某一项
      if(!propName || value === undefined) return;

      let list = this.courseList;
      if(list[index].course_id === course_id) {
        this.courseList[index][propName] = value;
        return;
      }

      //index不符合时,查找
      let targetIndex = this.courseList.findIndex(v => v.course_id == course_id);
      if(targetIndex != -1) {
        this.courseList[targetIndex][propName] = value;
      }
    },
    handleClose(done) {
      done();
      this.inputVal = "";
      this.dialogDetail.index = -1;
    },
    delCourse() {
      let item = this.dialogDetail.item;
      this.delVisible = false;
      this.DelCourse(item.course_id).then(res=>{
        this.dialogDetail.item = {};
        if(res.errorcode == 0) {
          this.$message("删除成功!");
          // 重新加载课程列表
          this.page = 1;
          this.getList();
        }
      }).catch(err => {
        this.dialogDetail.item = {};
      });
    },
    copyCourse(course_id) {
      this.CopyCourse(course_id).then(res=>{
        if(res.errorcode == 0) {
          this.$message("复制成功!");
          // 重新加载课程列表
          this.page = 1;
          this.getList();
        }
      }).catch(err => {
        console.log(err)
        if(err && (err.errorcode === 10010 || err.errorcode === 10011) ) {
          this.$message.closeAll()
          this.hintObj = {
            content: err.msg,
            version: ''
          }
          this.tipVisible = true;
        }
      });
    },
    save() {
      //保存 弹窗
      let value = this.inputVal;
      let type = this.dialogDetail.type; //保存类型 rename 重命名课程名称；addlabel 给课程添加标签
      this.dialogVisible = false;

      let {course_id} = this.dialogDetail.item;
    
      if(type === 'rename') {
        // 重命名
        this.RenameCourse({course_id,title:value}).then(res => {
          if(res.errorcode == 0) {
            // 更新list中的名称
            this.updateItem({course_id, index:this.dialogDetail.index, propName:"title", value});
          }
        }).catch();
      }else if(type === 'addlabel') {
        // 添加标签
        updateCourseTag({
          course_id: course_id,
          tag_name_list: this.dialogDetail.oldTag,
        }).then(res => {
          if(res.errorcode == 0) {
            this.$message.success(res.msg);
            
            res.data.tag_list.forEach((v,vind) => {
              if(v.tag_name === undefined) {
                res.data.tag_list[vind].tag_name = v.tag_info.tag_name;
              }
            });
            // 更新list中的名称
            this.updateItem({course_id, index:this.dialogDetail.index, propName:"tag_list", value:res.data.tag_list});
          }else {
            this.$message.warning(res.msg);
          }
        })
        
      }
    },
    getList() {
      // 获取课程列表
      let params = {keyword:this.keyword, sort_mode: this.sort_mode, page: this.page, limit: this.limit};
      if(this.tag_name && this.tag_name.length > 0) {
        this.tag_name.forEach((v,i) => {
          params[`tag_name_list[${i}]`] =  v;
        })
      }
      
      this.GetCourseList(params).then((res)=>{
        if(res.errorcode == 0) {
          this.courseList = this.page > 1 ?  this.courseList.concat(res.data.data) : (res.data.data || []);
          this.total = res.data.total;
        }else {
          this.total = -1;
        }
      }).catch(()=>{
        this.total = -1;
      });
    },
    commandVal(type, item, index) {
      return {
        type: type,
        item: item,
        index: index
      };
    },
    changeOrder(val) {
      // 排序方式
      this.sort_mode = val;
      this.page = 1;
      this.getList();
    },
    changeStyle(ind) {
      if (this.currentStyle == ind) return;
      this.currentStyle = ind;
      this.CHANGE_CURRENTCOURSESTYLE( ind );
    },
    toolshover(val, ind) {
      // 鼠标移入工具条
      this.showIndex = ind;
      this.isshow = val == 1 ? true : false;
    },
    handleTools({ type, item, index }) {
      // 工具栏 - 编辑、删除、编辑封面、重命名、添加标签

      this.dialogDetail.item = {};

      switch (type) {
        case "edit":
          this.$router.push("/course/edit/" + item.course_id + "?pageType=index");
          // this.$navBlank({
          //   path: "/course/edit/" + item.course_id,
          //   query: {
          //     pageType: "index"
          //   },
          //   pageId: item.course_id
          // });
          break;
        case "delete":
          this.dialogDetail.title = "删除";
          this.dialogDetail.index = index;
          this.dialogDetail.item = item;
          this.delVisible = true;
          break;
        case "changecover":
          this.coverDialogSet.item = item;
          this.coverDialogSet.index = index;
          this.coverDialogSet.visible = true;
          break;
        case "rename":
          this.inputVal = item.title;
          this.dialogDetail.index = index;
          this.dialogDetail.item = item;
          this.dialogDetail.type ="rename";
          this.dialogDetail.title = "重命名课程";
          this.dialogDetail.placeholder = "请输入课程名称";
          this.dialogDetail.label = "课程名称";
          this.dialogVisible = true;
          break;
        case "addlabel":
          this.dialogDetail.index = index;
          this.dialogDetail.item = item;
          this.dialogDetail.oldTag = item.tag_list.map(v => v.tag_name);
          
          this.dialogDetail.type ="addlabel";
          this.dialogDetail.title = "添加标签";
          this.dialogDetail.placeholder = "请输入标签，回车建立标签";
          this.dialogDetail.label = "标签名称";
          this.dialogVisible = true;
          break;
        case "previewcourseware":
          // 预览课程
          this.previewRequest(item);
          break;
        case "copying":
          // 复制课程
          this.copyCourse(item.course_id);
          break;
      }
    },
    toolsVisibleChange(ind) {},
    previewRequest(item) {
      // 预览课程
      this.previewLoading.visible = true;
      // let subFrame = window.open("/#/preview/"+item.course_id, "preview");
      // this.previewLoading.pageFrame = subFrame;
      if(this.previewLoading.pageFrame) this.previewLoading.pageFrame.close();
      if(this.is_web) {
        // 网页端
        this.previewLoading.pageFrame = window.open("/#/preview/"+item.course_id,"preview");
      }
      
      this.PreviewCourse(item.course_id).then(res => {
        if(res.errorcode == 0 && res.msg !== "打包失败") {
          // 打开新窗口
          if(this.is_web) {
            this.previewLoading.pageFrame.location.replace(res.data.preview_url);
          }else {
            // electron端
            let { href } = this.$router.resolve({
              path: '/preview/'+item.course_id,
              query:{
                url: res.data.preview_url
              }
            });
            this.previewLoading.pageFrame = window.open(href, "_blank");
          }
          this.previewLoading.visible = false;
        }else {
          // 预览出错
          this.previewLoading.visible = false;
          this.previewLoading.pageFrame && this.previewLoading.pageFrame.close();
          this.previewError(res, item.course_id);
        }
      }).catch(() => {
        this.previewLoading.visible = false;
        this.previewLoading.pageFrame && this.previewLoading.pageFrame.close();
      });
    },
    previewError(detail, course_id) {
      // 预览出错 - 弹窗显示
      this.previewDialogSet.items = Array.isArray(detail.data.errors) ? detail.data.errors : [detail.data.errors];
      this.previewDialogSet.visible = true;
      this.previewDialogSet.course_id = course_id;
    },
    cancelPreview() {
      // 取消预览
      this.previewLoading.pageFrame && this.previewLoading.pageFrame.close();
      this.previewLoading.visible = false;
    },
    navtoError({detail}) {
      this.previewDialogSet.visible = false;
      this.INIT_COURSE_RELATIVE({course_id:this.previewDialogSet.course_id});
      switch(detail.type) {
        case "article":
          this.$router.push({
            name: "EditContent",
            params: {
              type:'edit',
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id,
              is_redirect:1,
              article_id:detail.params.article_id
            },
            query: {
              pageType:'content',
            },
          });
          break;
        case "block":
          this.$router.push({
            name: "EditContent",
            params: {
              type:'edit',
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id,
              is_redirect:1,
              article_id:detail.params.article_id,
              block_id: detail.params.block_id
            },
            query: {
              pageType:'content',
            },
          });
          break;
        case "contentobject":
          this.$router.push({
            name: "EditContent",
            params: {
              type:'edit',
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id,
              is_redirect:1,
            },
            query: {
              pageType: detail.contentobject_type == 'page' ? 'content' : 'menu'
            },
          });
          break;
        case "component":
          this.$router.push({
            name: "EditContent",
            params: {
              type:'edit',
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id,
              is_redirect:1,
            },
            query: {
              pageType: 'content'
            },
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nodata {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .statu_img {
    margin-top: -50px;
    width: 91px;
    height: 96px;
  }
}
.el-select {
  width: 160px;
}
.el-dropdown-menu {
  text-align: left;
  min-width: 106px;
}
.course-container {
  box-sizing: border-box;
  padding: 32px 0 5px 32px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: #FAFAFA;
}
.list-toolbar {
  padding-right: 32px;
  .style-list {
    align-items: flex-end;
  }
  .list-select {
    display: flex;
    align-items: flex-end;
  }
  /deep/.fd-dropdown-container {
    align-items: flex-end;
  }
  /deep/.fd-dropdown-head {
    padding: 0 !important;
  }
  /deep/.fd-select__caret {
    color: #8C8C8C;
  }
}
@liMarginRight: 38px;
.list-container {
  padding-right: 32px;
  height: 100%;
  overflow: hidden auto;
  // 滚动条占宽会导致页面对不齐
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  /deep/.el-scrollbar__wrap {
    width: 100%;
    margin-right: 0 !important;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &.list_nodata /deep/.el-scrollbar__view {
    height: 100%;
  }
  &.grid_type {
    // 网格方式
    /deep/.el-scrollbar__view {
      // display: flex;
      // flex-wrap: wrap;
      // align-content: flex-start;
      // margin-right: calc(var(--right)); //抵消li的margin-right
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(224px,1fr));
      gap: 24px;
    }
    /deep/.el-scrollbar__bar.is-horizontal {
      display: none;
    }
    @media screen and (max-width: 1600px) {
      /deep/.el-scrollbar__view {
        grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
      }
    }
  }
}
.coursebox-wrapper {
  // overflow: hidden;
  // margin-bottom: 38px;
  // margin-right: @liMarginRight;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  // width: 260px;
  // height: 227px;

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
    .coursebox-mask {
      opacity: 1;
    }
  }
}
.coursebox-image {
  display: block;
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding-top: 65%;
  background-color: #f3f3f3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // width: 258px;
  // height: 155px;
}
.coursebox-mask {
  opacity: 0;
  transition: opacity 0.6s;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(102, 102, 102, 0.6) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  &.show {
    opacity: 1;
  }
}
.coursebox-mask-edit {
  position: absolute;
  right: 0;
  z-index: 100;
  width: 52px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.coursebox-mask-tools {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  // background-color: #fff;
  i {
    font-size: 40px;
  }
}
// 横排
@horizontalBoxHeight:100px;
.coursebox-horizontal {
  position: relative;
  box-sizing: border-box;
  // height: @horizontalBoxHeight;
  padding: 25px 0;
  &::before {
    content: "";
    display: none;
    position: absolute;
    z-index: 0;
    left: 8px;
    right: 8px;
    top: 16px;
    bottom: 16px;
    border-radius: 4px;
    background-color: #F7E7EA;
  }
  > div {
    position: relative;
    flex: 1;
    flex-shrink: 0;
    padding-left: 24px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    height: 1px;
    background-color:  #F0F0F0;
  }
  &:hover,
  &.show {
    &::before {
      display: block;
    }
  }
  &.show {
    .icon-gray-more:before {
      color: #595959;
    }
  }
}
.coursebox-horizontal-title {
  box-sizing: border-box;
  line-height: 22px;
  >p {
    max-width: 165px;
  }
}
.coursebox-horizontal-image {
  display: block;
  border-radius: 4px;
  width: 140px;
  height: @horizontalBoxHeight;
}
.coursebox-horizontal-time {
  // width: 360px; //1385
  word-break: normal;
  white-space:nowrap;
}
.coursebox-horizontal-tools {
  width: 30px;
  .icon-gray-more:before {
    color: #8C8C8C;
  }
  &:hover {
    .icon-gray-more:before {
      color: #595959;
    }
  }
  .tools-dropdown {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(24px - 15px);
  }
}
.coursebox-title {
  margin-top: 16px;
  box-sizing: border-box;
  // width: 228px;
  padding-right: 20px;
  height: 20px;
  line-height: 20px;
}
.coursebox-title /deep/p {
  // width: 220px;
  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
}

// 预览加载
  .preview-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .loading-img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
  .cancel-btn {
    border: 1px solid #D4D4D4;
    border-radius: 16px;
    margin-top: 40px;
    width: 78px;
    height: 32px;
    transition: background 0.6s;
    cursor: pointer;
    &:hover {
      background-color: #EDEDED;
    }
  }
  // 预览错误
  .course-preview-error {
    // 滚动区域
    .error-scrollview {
      border: 1px solid #D4D4D4;
      border-radius: 4px;
      height: 210px;
      background: #FFFFFF;
      // 重置滚动条
      /deep/.el-scrollbar__bar.is-vertical {
        right: 1px;
        width: 4px!important;
      }
      /deep/.el-scrollbar__thumb {
        border-radius: 3px;
        height: 64px!important;
        background-color: #A1A1A1;
      }
    }
  }

  // 水平
  .horizontal-header {
    margin-top: 18px;
    position: relative;
    border-radius: 4px 4px 0 0;
    padding-bottom: 1px;
    margin-right: 32px;
    justify-content: space-between;
    background-color: #FFFFFF;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 1px;
      background-color: #F0F0F0;
    }
    >span {
      padding: 14px 0 14px 24px;
      flex: 1;
      flex-shrink: 0;
    }
  }
  .coursebox-horizontal-tags {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    >span {
      padding-left: 20px;
      margin-top: 6px;
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: 16px 16px;
      background-image: url('~@/assets/other/course_tag.svg');
      &:first-child {
        margin-top: 0;
      }
      &.tags_dot {
        background-image: url('~@/assets/other/course_more_tag.svg');
        color: #006FFF;
      }
      &.tags_dot:hover {
        color: #0050B7;
        background-image: url('~@/assets/other/course_tag_actived.svg');
      }
    }
    .more_tags {
      display: none;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      left: 56px;
      width: 200px;
      border-radius: 4px;
      background-color: #FFFFFF;
      z-index: 2;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
      &.show {
        display: flex;
      }
      >p {
        padding: 10px 36px;
        background-repeat: no-repeat;
        background-position: 16px center;
        background-size: 16px 16px;
        background-image: url('~@/assets/other/course_tag.svg');
      }
    }
  }
</style>
