<template>
  <div class="fd-dropdown-container prel">
    <div class="flex">
      <div 
        v-clickoutside="handleClose"
        @click.stop="toggleMenu"
        class="fd-dropdown-head pointer">
        <span class="head-option">{{currentLabel}}</span><i class="fd-select__caret el-icon-arrow-up" :class="{'arrow-up':visible}"></i>
      </div>
    </div>
    <transition
      name="zoom-in-top"
      @before-enter="handleMenuEnter"
      @after-leave="doDestroy">
      <ul 
        ref="popper"
        v-show="visible"
        class="fd-dropdown-popper">
        <li
          v-for="(item, ind) in list"
          :key="ind"
          @mouseenter="hoverItem(item, ind)"
          @click.stop="selectOptionClick(item, ind)"
          class="fd-dropdown-item"
          :class="{'hover':selected.hoverIndex===ind}">{{item.label || item.value}}</li>
      </ul>
    </transition>
  </div>
</template>

<script>
import Clickoutside from 'element-ui/src/utils/clickoutside';
export default {
  directives: { Clickoutside },
  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    list: {
      type: Array,
      default: ()=> {
        return [];
      }
    }
  },
  computed: {
    currentLabel() {
      let tarIndex = this.list.findIndex(v => v[this.valueKey] === this.value);
      return tarIndex != -1 ? this.list[tarIndex].label : ""
    }
  },
  watch: {
    value: {
      immediate:true,
      handler(val) {
        if(val) {
          let tarIndex = this.list.findIndex(v => v[this.valueKey] === val);
          this.hoverItem(this.list[tarIndex], tarIndex);
        }
      },
    },
  },
  data() {
    return {
      visible: false,
      selected: {
        hoverIndex:-1,
      },
    };
  },
  methods: {
    toggleMenu() {
      this.visible = !this.visible;
    },
    handleMenuEnter() {
      // console.log('handleMenuEnter',);
    },
    doDestroy() {
      // console.log('doDestroy');
    },
    hoverItem(item, ind) {
      this.selected.hoverIndex = ind;
    },
    selectOptionClick(item, ind) {
      this.selected.item = item;
      this.selected.index = ind;
      this.visible = false;
      this.$emit("change",item.value);
    },
    handleClose() {
      this.visible = false;
    },
  },
}
</script>

<style lang="less">
  .zoom-in-top-enter-active,
  .zoom-in-top-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center top;
  }
  .zoom-in-top-enter,
  .zoom-in-top-leave-active {
    opacity: 0;
    transform: scaleY(0);
  }
  .fd-dropdown-head {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px 0;
    min-width: 160px;
  }
  .head-option {
    padding-right: 8px;
  }
  .fd-select__caret {
    color: #C0C4CC;
    font-size: 14px;
    transition: transform .3s;
    transform: rotateZ(180deg);
    cursor: pointer;
    &.arrow-up {
      transform: rotateZ(0);
    }
  }
  .fd-dropdown-popper {
    position: absolute;
    z-index: 999;
    bottom: 0;
    transform: translateY(100% + 4px);
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #E4E7ED;
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0,0,0, 0.1);
    box-sizing: border-box;
    margin: 5px 0;
    min-width: 160px;
    transform-origin: center top;
    list-style: none;
  }
  .fd-dropdown-item {
    font-size: 14px;
    padding: 0 16px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #222222;
    box-sizing: border-box;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    transition: background 0.6s;
    &:hover,&.hover {
      background-color: #EDEDED;
    }
  }
</style>