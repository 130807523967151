<template>
  <div class="error-nav-item flex flex_acenter flex_jcbetween" :class="{'pointer': detail.is_redirect == 1}" @click="tapItem">
    <div class="flex flex_acenter">
      <i class="icon-warning fontsize_16"></i>
      <p class="fontsize_12 color_222 padding_left8 content pover">{{detail.msg}}</p>
    </div>
    <div v-if="detail.is_redirect == 1" class="flex flex_acenter">
      <span class="fontsize_12 color_E74362 btn">前往修改</span>
      <i class="icon-gray-down-arrow fontsize_10 icon_color_E74362 right-arrow"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type:Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    tapItem() {
      if(this.detail.is_redirect == 0) return;
      this.$emit("click", {detail: this.detail});
    }
  }
}
</script>

<style lang="less" scoped>
  .error-nav-item {
    box-sizing: border-box;
    border: 1px solid #E74362;
    border-radius: 4px;
    height: 42px;
    padding: 0 14px;
    background: rgba(231,67,98,0.10);
    .content {
      width: 402px;
    }
    .btn {
      padding-right: 12px;
    }
    .right-arrow {
      transform: rotate(-90deg);
    }
  }
</style>